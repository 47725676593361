import { ChangeEvent, useState } from "react";
import Product from "../../../../lib/uma/products";

interface IProductsDropdown {
  access: Array<string>;
  products: Array<Product>;
  onProductSelect: (product: Product) => void;
}

function ProductsDropdown(props: IProductsDropdown) {
  const [productSelected, setProudctSelected] = useState<Product>();

  const selectProduct = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") {
      setProudctSelected(undefined);
      return;
    }
    const p = props.products.filter((p) => p.productName === e.target.value)[0];
    setProudctSelected(p);
    props.onProductSelect(p);
  };

  // group products by collection name, and then sort alphebetically in each group
  const groupedProducts: Record<string, Product[]> = {};
  props.products.forEach((product) => {
    const collection = product.collection || "No Collection";
    if (!(collection in groupedProducts)) groupedProducts[collection] = [];
    groupedProducts[collection].push(product);
  });
  Object.keys(groupedProducts).map((collectionName) => groupedProducts[collectionName].sort(
    (a, b) => a.productName.localeCompare(b.productName)
  ));

  return (
    <div style={{ marginTop: "1rem" }} className="form-group">
      <label htmlFor="product-selector">Select Product</label>
      <select
        defaultValue=""
        onChange={selectProduct}
        className="form-control"
        id="product-selector"
        title={"Select a product"}
      >
        <option key={null}></option>
        {Object.keys(groupedProducts).flatMap((collectionName) => {
          return (
            <>
              <option key={collectionName} disabled={true} style={{ backgroundColor: "rgb(220 220 220)" }}>--- {collectionName} ---</option>
              {groupedProducts[collectionName].map((product) => {
                return (
                  <option key={product.docId} disabled={props.access.includes(product.docId)}>
                    {product.productName}
                  </option>
                )
              })}
            </>
          )
        })}
      </select>
    </div>
  );
}

export default ProductsDropdown;
export {};
