import React from "react";
import MaskedInput from "react-input-mask";

const CustomInput = React.forwardRef((props, ref) => (
  <div style={{ fontFamily: "Montserrat, sans-serif" }}>
    {props.label ? (
      <span>
        <label htmlFor={props.name}>{props.label}</label>
        <br />
      </span>
    ) : (
      <span />
    )}
    {/* TODO: fix the mask below. package switched from react-text-mask to react-input-mask 
      and the masking characters are different
    */}
    {props.type && props.type === "expiration_date" ? (
      <MaskedInput
        mask={[/[0-9]/, /[0-9]/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={" "}
        style={{ width: props.width ? props.width : "100%" }}
        className="form-control"
        id={props.name}
        onChange={props.onChange}
        value={props.value ? props.value : ""}
        placeholder={props.placeholder ? props.placeholder : ""}
        title={props.title}
        ref={ref}
      />
    ) : (
      <input
        style={{ width: props.width ? props.width : "100%" }}
        className="form-control"
        id={props.name}
        type={props.type ? props.type : "text"}
        onChange={props.onChange}
        value={props.value ? props.value : ""}
        placeholder={props.placeholder ? props.placeholder : ""}
        title={props.title}
        ref={ref}
      />
    )}
  </div>
));

export default CustomInput;
