import { createContext, PropsWithChildren } from "react";
import { shopify } from "./lib/api";

const APIContext = createContext<typeof shopify>(shopify);

const CloudFunctionsProvider = (props: PropsWithChildren) => {
  return (
    <APIContext.Provider value={shopify}>
      {props.children}
    </APIContext.Provider>
  );
};

export default CloudFunctionsProvider;
export { APIContext };
