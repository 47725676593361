import React, { cloneElement } from "react";

// Moving context to a different file to avoid circular dependencies
// between this file and child components
import { CloudFunctionsProvider as OldCloudFunctionsProvider } from "./Context";
import CloudFunctionsProvider from "./APIContext";

// Local dependencies
import LoginPage from "./components/pages/Login";
import ExportsPage from "./components/Exports";
import CompaniesPage from "./components/pages/companies/Landing";
import CompanyPage from "./components/pages/company/EditCompany";
import NewCompanyPage from "./components/pages/company/NewCompany";
import NewCompanySubscriptionPage from "./components/pages/company/access/NewAccess";
import NewUserSinglePage from "./components/pages/company/users/NewSingleUser";
import EditUserPage from "./components/pages/company/users/EditUser";
import ErrorPage from "./components/pages/Error";
import Navigation from "./components/Navigation";
// import LoadingCircle from "./components/misc/Loading";
import "./App.css";

// Using React Router v6
import { Routes, Route, useParams, useLocation, Navigate, Outlet } from "react-router-dom";

import { db } from "./lib/firebase";
import { shopify } from "./lib/api";
import { useAuth, UserContext, useSession } from "./lib/auth";
import { InstallPage } from "./components/pages/Install";

function App() {
  const location = useLocation();
  const {initializing, user} = useAuth();

  return (
    <>
      {user ? (
        <Navigation />
      ) : (
        <></>
      )}
      <div className="App">
        <CloudFunctionsProvider>
          <OldCloudFunctionsProvider shopify={shopify}>
            <UserContext.Provider value={{ initializing, user }}>
              {/* check if initializing, display loading circle */}
              {/* check if user is authenticated, redirect to login */}
              {!initializing && !user && location.pathname !== "/login" ? (
                <Navigate to="login" state={{ redirect: location.pathname }} />
              ) : (
                <></>
              )}
              <Outlet />
            </UserContext.Provider>
          </OldCloudFunctionsProvider>
        </CloudFunctionsProvider>
      </div>
    </>
  )
}

// TODO: any routes/components below that are utilizing PassParams as a wrapper
// component are old class components that should be refactored to Typescript
// and React's new function component syntax
function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/install" element={<InstallPage />} />

        <Route path="" element={<Navigate to={"/companies/1"} />} />
        <Route path="companies" element={<Navigate to={"/companies/1"} />} />
        <Route path="companies/:pageNumber" element={<CompaniesPage />} />
        <Route path="company">
          <Route path=":companyId" element={
            <PassParams><CompanyPage db={db} /></PassParams>
          }/>
          <Route path=":companyId/new-access" element={
            <PassParams><NewCompanySubscriptionPage /></PassParams>
          }/>
          <Route path=":companyId/new-user" element={
            <PassParams><NewUserSinglePage db={db} /></PassParams>
          }/>
          <Route path=":companyId/edit-user/:userId" element={
            <PassParams><EditUserPage db={db} /></PassParams>
          }/>
        </Route>
        <Route path="new-company" element={
          <PassParams><NewCompanyPage db={db} /></PassParams>
        }/>
        <Route path="exports" element={
          <PassParams><ExportsPage db={db} /></PassParams>
        }/>

        <Route path="*" element={<ErrorPage errorCode="404" />} />
      </Route>
    </Routes>
  );
}

// React will only allow us access to routing paramaters through functional components
// which is why I have PassParams wrapping over my class components above.
function PassParams(props: any) {
  const user = useSession();
  const history = useLocation();
  const params = useParams();
  // Object.keys(params).forEach((key) => {
  //   if (isNumeric(params[key])) {
  //     params[key] = parseInt(params[key]);
  //   }
  // });
  const children = React.Children.map(props.children, (child) => {
    return cloneElement(child, {
      ...params,
      history: history,
      user: user
    });
  });
  return <>{children}</>;
}

// helper function to check if a string can be converted to an int
// const isNumeric = (str: string) => {
//   if (typeof str != "string") return false;
//   return !isNaN(parseFloat(str));
// };

export default AppRoutes;
