import React from "react";
import { Link } from "react-router-dom";

// Sort of breadcrumbs but not really
export default function Breadcrumbs(props) {
  const pathways = props.pathways
    ? props.pathways
    : [["Companies", "/companies"]];
  return (
    <span className={props.className} style={props.style}>
      {/* <span>/ </span> */}
      {pathways.map((path, index) => {
        return (
          <span key={index}>
            {path[1] ? (
              <Link title={`Navigate to "${path[0]}"`} to={path[1]}>
                {path[0]}
              </Link>
            ) : (
              <span>{path[0]}</span>
            )}
            {index < pathways.length ? <span> / </span> : <span />}
          </span>
        );
      })}
    </span>
  );
}
