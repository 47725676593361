import { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../lib/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

import Alert from "../misc/Alert";

interface IAlert {
  msg: string;
  type: string;
  timeout?: string | number | NodeJS.Timeout;
}

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertState, setAlertState] = useState<IAlert>({msg: "", type: ""});

  const navigate = useNavigate();

  // TODO: this componenet might need to be fixed. the below can potentially
  // trigger state updates from the timeout after the component has unmounted
  const setAlert = (msg: string, alertType: string, time: number) => {
    cancelAlert();
    const timer = setTimeout(() => {
      setAlertState({
        msg: "",
        type: ""
      });
    }, time);
    setAlertState({
      msg: msg,
      type: alertType,
      timeout: timer
    });
  };

  const cancelAlert = () => {
    if (alertState.timeout) {
      clearTimeout(alertState.timeout);
    }
  };

  const checkLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // props.logIn();
      cancelAlert();
      navigate("/companies/1");
    } catch (error: any) {
      if (error && error.message) {
        setAlert(error.message, "danger", 5000);
      }
      console.error(error);
    }
  };

  return (
    <div style={{ fontFamily: "Montserrat, sans-serif", marginTop: "3rem" }}>
      <div className="m0 signup-form-inner">
        <h1 style={{ fontWeight: "bold" }}>Log in</h1>
        <form onSubmit={checkLogin}>
          <div style={{ marginTop: "1rem" }}>
            <label htmlFor="signup-email">Email Address</label>
            <br />
            <input
              className="form-control"
              type="email"
              id="signup-email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div style={{ marginTop: "1rem" }}>
            <label htmlFor="signup-password">Password</label>
            <br />
            <input
              className="form-control"
              type="password"
              id="signup-password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", height: "3.5rem" }}>
            <input
              className="btn btn-primary"
              value="Log in"
              type="submit"
              style={{ marginTop: "1rem" }}
            />
            <Alert
              style={{ margin: 0, marginTop: "1rem", marginLeft: "1rem" }}
              msg={alertState.msg}
              alertType={alertState.type}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
