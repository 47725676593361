// NPM Dependencies
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addDoc, collection, updateDoc } from "firebase/firestore";

// Local Dependencies
// import CustomInput from "../../../misc/CustomInput";
import Alert from "../../../misc/Alert";
import Breadcrumbs from "../../../misc/Breadcrumbs";
import PageHeader from "../../../misc/PageHeader";
import LoadingCircle from "../../../misc/Loading";
import DateInput from "./DateInput";
import { auth, db } from "../../../../lib/firebase";
import ProductsDropdown from "./ProductsDropdown";
import UsersCheckboxes from "./UsersCheckboxes";

// Context
import CloudFunctions from "../../../../Context";
import Company from "../../../../lib/uma/companies";
import Client from "../../../../lib/uma/clients";
import Access from "../../../../lib/uma/access";
import Product, { getProducts, IProductRLS } from "../../../../lib/uma/products";

import RLSDimsSelector, { IDims } from "./RLSDims";
import { setHours } from "date-fns";

interface IAlert {
  msg: string;
  type: string;
  timeout?: string | number | NodeJS.Timeout;
}

function NewCompanySubscriptionPage() {
  const shopifyFunctions = useContext(CloudFunctions);
  const params = useParams();
  const navigate = useNavigate();

  const [company, setCompany] = useState<Company>();
  const [clients, setClients] = useState<Client[]>();
  const [products, setProducts] = useState<Product[]>();
  const [access, setAccess] = useState<Access[]>([]);
  const [alertState, setAlertState] = useState<IAlert>({ msg: "", type: "" });
  const [expirationDate, setExpirationDate] = useState<Date>();
  const [clientsSelected, setClientsSelected] = useState<Client[]>();
  const [productSelected, setProductSelected] = useState<Product>();
  const [rlsValues, setRlsValues] = useState<IDims[] | null>();
  const [rlsValuesSelected, setRlsValuesSelected] = useState<IDims[]>();
  const [isSubmitted, setIsSubmitted] = useState(false);

  // setup company and listeners for products, accesses, and users
  useEffect(() => {
    const company = new Company({ docId: params.companyId });
    company.getCompanyFromFirestore().then((doc) => {
      setCompany(company);
    }, (err) => {
      console.log(err);
    });

    const accessUnsub = company.getAccessListener((snap) => {
      setAccess(snap);
    }, (err) => {
      console.log(err);
    });

    const clientUnsub = company.getClientListener((snap) => {
      setClients(snap);
    }, (err) => {
      console.log(err);
    });

    return () => {
      accessUnsub();
      clientUnsub();
    };
  }, [params.companyId]);

  useEffect(() => {
    getProducts().then(
      (products) => setProducts(products),
      (err) => console.log(err)
    )
  }, []);

  useEffect(() => {
    // fetch RLS Dims
    if (productSelected && productSelected.rls) {
      shopifyFunctions.getRlsDimensions({ fpid: productSelected.docId }).then((result: any) => {
        console.log(result);
        if (result.data) {
          setRlsValues(result.data);
          setRlsValuesSelected([]);
        }
      }, (err: Error) => {
        setRlsValues(null);
        console.log(err);
      })
    }
  }, [shopifyFunctions, productSelected]);

  // TODO: this componenet might need to be fixed. the below can potentially
  // trigger state updates from the timeout after the component has unmounted
  const setAlert = (msg: string, alertType: string, time: number) => {
    cancelAlert();
    const timer = setTimeout(() => {
      setAlertState({
        msg: "",
        type: ""
      });
    }, time);
    setAlertState({
      msg: msg,
      type: alertType,
      timeout: timer
    });
  };

  const cancelAlert = () => {
    if (alertState.timeout) {
      clearTimeout(alertState.timeout);
    }
  };

  const selectProduct = (product: Product) => {
    setProductSelected(product);
    setRlsValuesSelected(undefined);
  };

  // TODO: finish implementing submit function
  const submit = async () => {
    console.log(rlsValuesSelected);
    console.log(productSelected);
    console.log(expirationDate);
    console.log(clientsSelected);

    // 1. validate expirationDate is good, if not throw an alert on page
    // 2. validate a product is selected, if not throw an alert on page
    // 3. double-check to make sure the company doesn't already have
    // access to this product, throw an alert if they do
    // 4. create the new access doc or call company.newAccess()
    // 5. add the new log called "access_created"
    // 6. call shopify API
    // 7. redirect back to company page
    // const checkboxes = this.state.checkboxes;

    // data validation checks
    if (!expirationDate) {
      setAlert("Please provide a valid expiration date.", "warning", 5000);
      return;
    } else if (!productSelected) {
      setAlert("Please select a product.", "warning", 5000);
      return;
    } else if (productSelected.rls && !rlsValuesSelected?.find(
      (dim) => dim.rows.length !== 0)
    ) {
      setAlert("This is an RLS product. Please select at least one value for each dimension.", "warning", 5000);
      return;
    }

    // const newDate = new Date(
    //   new Date(expirationDate).setHours(23, 55)
    // );

    if (!company) {
      throw new Error();
    } else if (!products) {
      throw new Error();
    }

    // pull in this companies access & validate that we don't already have
    // access to this product
    const currentAccess = await company.getAccess();

    if (currentAccess.filter((a) => a.productId === productSelected.docId).length > 0) {
      setAlert(
        "This company already has access to that product!",
        "warning",
        5000
      );
      return;
    }

    setIsSubmitted(true);

    const transformedExpDate = setHours(expirationDate, 23);

    // TODO: double-check expiration date is correct using getUTCMilliseconds
    const newAccess = await company.newAccess(
      productSelected.docId,
      transformedExpDate,
      rlsValuesSelected,
    );

    const logsRef = collection(db, `/companies/${company.docId}/logs`);
    const logRef = await addDoc(logsRef, {
      log_type: "access_created",
      status: "pending",
      timestamp: new Date(),
      admin_id: auth.currentUser?.uid,
      admin_email: auth.currentUser?.email,
      specifics: {
        company_id: company.docId,
        access_id: newAccess.docId,
        expiration_date: transformedExpDate, // TODO: double-check what format this date needs to be in
        product_id: productSelected.docId,
        product_name: productSelected.productName,
      },
    });

    try {
      const clientIds = ((clientsSelected !== undefined ? clientsSelected : clients) || []).map(
        (client) => client.docId
      );
      const response = await shopifyFunctions.newCompanyAccess({
        product_id: productSelected.docId,
        company_id: company.docId,
        log_id: logRef.id,
        user_ids: clientIds,
      });
      // grant RLS to all selected users if this is an RLS product
      if (rlsValuesSelected && productSelected.rls) {
        const rlsGrantPromises = clientIds.flatMap((clientId) => {
          return rlsValuesSelected.map((rlsDim) => {
            const rlsGrant = {
              accessTable: (productSelected.rls as IProductRLS).accessTable,
              userId: clientId,
              dimName: rlsDim.dimTable.split(".")[2],
              dimValues: rlsDim.rows
            };
            return shopifyFunctions.grantRlsAccessToClient(rlsGrant);
          });
        });
        await Promise.all(rlsGrantPromises);
      }
      if ("data" in response && response.data.message === "0 jobs created") {
        updateDoc(logRef, { status: "pointless" })
      } else {
        updateDoc(logRef, { status: "in_queue" })
      }
    } catch (err) {
      console.error(err);
    }

    // redirect back to company page
    navigate(`/company/${company.docId}`);
  };

  let subscriptionIds: string[], remainingProducts = []
  if (products) {
    subscriptionIds = access.map((sub) => sub.productId);
    remainingProducts = (products || []).filter(
      (product) => !subscriptionIds.includes(product.docId)
    );
  }

  return (
    <div className="m0 listings-wrapper middle half-width">
    <Breadcrumbs
      style={{ marginTop: "1rem" }}
      pathways={[
        ["Companies", "/companies"],
        ["Company", `/company/${params.companyId}`],
        ["New Access"],
      ]}
    />
    <PageHeader
      small={"New Access"}
      big={company ? company.companyName : ""}
      tiny={params.companyId}
    />
    {products === undefined ? (
      <LoadingCircle />
    ) : remainingProducts.length < 1 ? (
      <div className="alert alert-info" style={{ marginTop: "1rem" }}>
        This company already has access to all products! Click{" "}
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "teal",
            padding: 0,
            margin: 0,
          }}
          onClick={() => navigate(`companies/${params.companyId}`)}
        >
          <b>here</b>
        </button>{" "}
        to return.
      </div>
    ) : (
      <div>
        {/* date input */}
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <div style={{ marginTop: "1rem", width: "50%" }}>
            <DateInput
              inputBoxLabel="Expiration Date"
              name="expiration_date"
              onValidDateChange={(date) => setExpirationDate(date)}
              requiresFutureDate
            />
          </div>
        </div>

        {/* product dropdown */}
        <ProductsDropdown
          access={access.map((a) => a.productId)}
          products={products}
          onProductSelect={(p) => selectProduct(p)}
        />

        {/* TODO: add RLS to Product class and do a check here to see if RLS key
          * is present on productSelected before rendering RLSDimsSelector
          */}
        {(productSelected && productSelected.rls) ? (
          <RLSDimsSelector
            rlsValues={rlsValues}
            dimsSelected={rlsValuesSelected || []}
            updateRlsSelectedValues={setRlsValuesSelected}
          />
        ) : (
          <></>
        )}

        {/* users checkboxes selection */}
        {clients === undefined ? (
          <LoadingCircle />
        ) : (
          <UsersCheckboxes
            clients={clients}
            clientsSelected={clientsSelected}
            onClientsUpdated={(clients) => setClientsSelected(clients)}
          />
        )}

        {/* TODO:below is a flex box for no reason */}
        {/* submit/create access button */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
            marginBottom: "3rem"
          }}
        >
          <Alert
            style={{ marginRight: "1rem" }}
            msg={alertState.msg}
            alertType={alertState.type}
          />
          <div style={{ marginLeft: "auto" }}>
            <button
              className="btn btn-primary btn-sm"
              onClick={submit}
              title={"Create new access"}
              disabled={isSubmitted}
            >
              Create Access
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
  )
}

export default NewCompanySubscriptionPage;
export {}