// NPM Dependencies
import React, { Component } from "react";

import Moment from "react-moment"; // JSX stuff

// Local Dependencies
import LoadingCircle from "./misc/Loading";

const logsWrapperStyle = {
  display: "grid",
  gridTemplateColumns: "auto 1fr auto auto",
};

function DrawLogs(props) {
  if (props.logs === undefined) return <LoadingCircle />;
  let striped = true; // for alternating background color
  return (
    <div className={props.className} style={props.style}>
      <h4 className="font-weight-bold" style={{ width: "fit-content" }}>
        Logs
      </h4>
      {props.logs.length < 1 ? (
        <div className="alert alert-info">No logs to display yet...</div>
      ) : (
        <React.Fragment />
      )}
      <div style={logsWrapperStyle}>
        <u>Time</u>
        <u>Details</u>
        <u style={{ paddingLeft: "0.25rem" }}>Status</u>
        <u></u>
        {props.logs.map((doc) => {
          striped = !striped;
          const data = doc.data();
          if (Routing[data.log_type]) {
            return (
              <React.Fragment key={doc.id}>
                <Log
                  striped={striped}
                  restoreCompany={props.restoreCompany}
                  doc={doc}
                  data={data}
                ></Log>
              </React.Fragment>
            );
          } else {
            return <React.Fragment key={doc.id} />;
          }
        })}
      </div>
      {props.isLoadingMoreLogs ? (
        <LoadingCircle style={{ marginTop: "0.75rem" }} />
      ) : (
        <React.Fragment />
      )}
      {props.showMore ? (
        <React.Fragment>
          <hr style={{ margin: "1rem 0" }} />
          <button
            className="btn btn-link"
            style={{ marginLeft: 0, padding: 0, fontSize: "14px" }}
            disabled={props.showMoreDisabled}
            onClick={props.showMore}
          >
            Show more
          </button>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}

class Log extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      mouseOver: false,
    };
    this.style = {
      // position: "relative",
      // display: "grid",
      // gridTemplateColumns: "6.5rem 1fr 0.3fr 1.5rem",
      // gridTemplateColumns: "auto auto auto 1.5rem",
      overflow: "hidden",
      paddingRight: "0.5rem",
      cursor: "pointer",
    };
  }

  dynamicStyle = () => ({
    wordWrap: this.state.expanded ? "break-word" : "unset",
    whiteSpace: this.state.expanded ? "normal" : "nowrap",
    backgroundColor: this.state.mouseOver
      ? "lightgrey"
      : this.props.striped
      ? "#f7f7f7"
      : "white",
  });

  render() {
    const calendarStrings = {
      lastDay: "[Yesterday]",
      sameDay: "LT",
      nextDay: "MM/DD/YYYY",
      lastWeek: "MM/DD/YYYY",
      nextWeek: "MM/DD/YYYY",
      sameElse: "MM/DD/YYYY",
    };
    const expandedCalendarStrings = {
      lastDay: "[Yesterday at] LT",
      sameDay: "[Today at] LT",
      nextDay: "MM/DD/YYYY [at] LT",
      lastWeek: "MM/DD/YYYY [at] LT",
      nextWeek: "MM/DD/YYYY [at] LT",
      sameElse: "MM/DD/YYYY [at] LT",
    };

    const calender = this.state.expanded
      ? expandedCalendarStrings
      : calendarStrings;

    return (
      <React.Fragment key={this.props.doc.id}>
        {/* drawing log timestamp */}
        <span
          style={{
            overflow: "hidden",
            paddingRight: "0.5rem",
            ...this.dynamicStyle(),
          }}
        >
          <Moment calendar={calender}>{Routing._time(this.props.data)}</Moment>
        </span>
        {/* drawing log text */}
        <span
          style={{
            ...this.style,
            ...this.dynamicStyle(),
          }}
          onClick={() => this.setState({ expanded: !this.state.expanded })}
          onMouseOver={() => this.setState({ mouseOver: true })}
          onMouseLeave={() => this.setState({ mouseOver: false })}
        >
          {Routing[this.props.data.log_type](
            this.props.doc,
            this.props.data,
            this.props.restoreCompany,
            this.state.expanded
          )}
        </span>
        {/* drawing log status text */}
        <span style={{ paddingLeft: "0.25rem", ...this.dynamicStyle() }}>
          {drawStatus(this.props.data)}
        </span>
        {/* hover over popup icon */}
        <span
          style={{
            backgroundColor: "transparent",
            width: "1.5rem",
            textAlign: "center",
            fontSize: "1rem",
            ...this.dynamicStyle(),
          }}
        >
          {this.state.expanded ? (
            <i className="far fa-minus-square"></i>
          ) : this.state.mouseOver ? (
            <i className="fas fa-expand-arrows-alt"></i>
          ) : (
            <i />
          )}
        </span>
      </React.Fragment>
    );
  }
}

const Routing = {
  company_deleted: (doc, data, restoreCompany, expanded) => {
    return co_delete(data, Routing._time(data), doc, restoreCompany);
  },
  company_created: (doc, data, _r, expanded) => {
    return co_added(data, Routing._time(data));
  },
  company_restore: (doc, data, _r, expanded) => {
    return co_restore(data, Routing._time(data));
  },
  company_name_change: (doc, data, _r, expanded) => {
    return co_name_change(data, Routing._time(data));
  },
  access_deleted: (doc, data, _r, expanded) => {
    return access_deleted(data, Routing._time(data), expanded);
  },
  access_created: (doc, data, _r, expanded) => {
    return access_created(data, Routing._time(data), expanded);
  },
  access_change: (doc, data, _r, expanded) => {
    return access_change(data, Routing._time(data));
  },
  access_toggled: (doc, data, _r, expanded) => {
    return access_toggled(data, Routing._time(data), expanded);
  },
  user_deleted: (doc, data, _r, expanded) => {
    return user_deleted(data, Routing._time(data), expanded);
  },
  user_added: (doc, data, _r, expanded) => {
    return user_added(data, Routing._time(data), expanded);
  },
  user_change: (doc, data, _r, expanded) => {
    return user_change(data, Routing._time(data), expanded);
  },
  mass_import_event: (doc, data, _r, expanded) => {
    return mass_import(doc, data, _r, expanded);
  },

  _time: (data) => data.timestamp.toDate(),
};

const drawStatus = (data) => {
  const style = {};
  return data.status === "resolved" ? (
    <i style={{ color: "green", ...style }}>{"✔ Resolved"}</i>
  ) : data.status === "pointless" ? (
    <i style={{ color: "grey", ...style }}>{"✔ Resolved"}</i>
  ) : data.status === "in_queue" ? (
    <i style={{ color: "blue", ...style }}>In Queue...</i>
  ) : data.status === "pending" ? (
    <i style={{ color: "grey", ...style }}>Creating job...</i>
  ) : data.status === "cancelled" ? (
    <i style={{ color: "grey", ...style }}>Cancelled</i>
  ) : data.status === "searching" ? (
    <i style={{ color: "blue", ...style }}>In Queue...</i>
  ) : data.status === "consolidated" ? (
    <i style={{ color: "grey", ...style }}>{"✔ Consolidated"}</i>
  ) : data.status === "error" ? (
    <i style={{ color: "red", ...style }}>Error</i>
  ) : (
    <React.Fragment />
  );
};

const drawStatusExtra = (data) => {
  const style = { marginLeft: "1rem" };
  return data.status === "resolved" ? (
    <i style={{ color: "green", ...style }}>{`✔ Resolved${
      data.associated_shopify_customer
        ? data.associated_shopify_customer.customer
          ? ` (Shopify Customer ID: ${data.associated_shopify_customer.customer.id})`
          : ""
        : ""
    }`}</i>
  ) : data.status === "pointless" ? (
    <i style={{ color: "grey", ...style }}>
      {"✔ Resolved (no users to update)"}
    </i>
  ) : data.status === "in_queue" ? (
    <i style={{ color: "blue", ...style }}>Job in queue...</i>
  ) : data.status === "pending" ? (
    <i style={{ color: "grey", ...style }}>Creating job...</i>
  ) : data.status === "cancelled" ? (
    <i style={{ color: "grey", ...style }}>Cancelled by inverse operation</i>
  ) : data.status === "searching" ? (
    <i style={{ color: "blue", ...style }}>
      Customer creation failed because customer already exists in Shopify.
      Querying Shopify for existing customer...
    </i>
  ) : data.status === "consolidated" ? (
    <i style={{ color: "grey", ...style }}>
      {`✔ Consolidated into ${
        data.consolidated_into
          ? `job id: ${data.consolidated_into}`
          : "another job"
      }`}
    </i>
  ) : data.status === "error" ? (
    <i style={{ color: "red", ...style }}>{JSON.stringify(data.errors)}</i>
  ) : (
    ""
  );
};

const drawJobs = (data) =>
  data.jobs ? (
    <i style={{ color: "grey", marginLeft: "0.5rem" }}>
      (jobs created: {Object.keys(data.jobs).length})
    </i>
  ) : (
    ""
  );

const mass_import = (data, time) => (
  <p
    style={{
      justifySelf: "start",
      alignSelf: "center",
      marginBottom: "0.25rem",
    }}
  >
    {`Customers imported via CSV ${
      data.companies_created ? data.companies_created : ""
    }`}
  </p>
);

const co_delete = (data, time, doc, restoreCompany) => (
  <p
    style={{
      justifySelf: "start",
      alignSelf: "center",
      marginBottom: "0.25rem",
    }}
  >
    <b>{data.specifics.company_name}</b> was deleted by{" "}
    <b>{data.admin_email}</b>
    {(!data.was_restored ||
      (data.was_restored && data.was_restored === false)) &&
    restoreCompany ? (
      <span>
        {" "}
        |{" "}
        <button
          className="btn btn-link"
          style={{
            margin: 0,
            padding: 0,
            fontSize: "0.8125rem",
          }}
          onClick={() =>
            restoreCompany(
              data.specifics.company_id,
              data.specifics.company_name,
              doc.id
            )
          }
        >
          Restore
        </button>
      </span>
    ) : (
      <React.Fragment />
    )}
    {/* (<Moment format="hh:mm" fromNow>{time}</Moment>) <b>{data.company_name}</b> was deleted by <b>{data.user_email}</b> | <a href="#">Restore</a> */}
  </p>
);

const co_added = (data, time) => (
  <p
    style={{
      justifySelf: "start",
      alignSelf: "center",
      marginBottom: "0.25rem",
    }}
  >
    <b>{data.specifics.company_name}</b> was created by{" "}
    <b>{data.admin_email}</b>
  </p>
);

const co_restore = (data, time) => (
  <p
    style={{
      justifySelf: "start",
      alignSelf: "center",
      marginBottom: "0.25rem",
    }}
  >
    <b>{data.specifics.company_name}</b> was restored by{" "}
    <b>{data.admin_email}</b>
  </p>
);

const co_name_change = (data, time) => (
  <p
    style={{
      justifySelf: "start",
      alignSelf: "center",
      marginBottom: "0.25rem",
    }}
  >
    <b>{data.specifics.old_name}</b>'s name changed to{" "}
    <b>{data.specifics.new_name}</b> by <b>{data.admin_email}</b>
  </p>
);

const access_deleted = (data, time, expanded) => (
  <p
    style={{
      justifySelf: "start",
      alignSelf: "center",
      marginBottom: "0.25rem",
    }}
  >
    company access to product{" "}
    <b>{data.specifics.access_details.product_name}</b> was deleted by{" "}
    <b>{data.admin_email}</b> {drawStatus(data)}
    {expanded ? drawJobs(data) : ""}
  </p>
);

const access_created = (data, time, expanded) => (
  <p
    style={{
      justifySelf: "start",
      alignSelf: "center",
      marginBottom: "0.25rem",
    }}
  >
    company access to product <b>{data.specifics.product_name}</b> has been
    created with an expiration date of{" "}
    <b>
      <Moment format="MM/DD/YYYY">
        {data.specifics.expiration_date.toDate()}
      </Moment>
    </b>{" "}
    by <b>{data.admin_email}</b>
    {expanded ? drawJobs(data) : ""}
  </p>
);

const access_change = (data, time) => {
  if (!data.specifics) {
    return <React.Fragment />;
  }
  const old_details = data.specifics.user_details;
  const new_details = data.specifics.new_user_details;

  let old_exp_date = `${Moment(old_details.expiration_date.toDate()).format(
    "MM/DD/YYYY"
  )}`;
  let new_exp_date = `${Moment(new_details.expiration_date.toDate()).format(
    "MM/DD/YYYY"
  )}`;

  let old_product = old_details.product_id;
  let new_product = new_details.product_id;

  return (
    <p
      style={{
        justifySelf: "start",
        alignSelf: "center",
        marginBottom: "0.25rem",
      }}
    >
      Access with product <b>{old_details.product_name}</b> details changed to{" "}
      <b>
        {old_exp_date !== new_exp_date
          ? `expiration date: ${new_exp_date}${
              old_product !== new_product ? ", " : ""
            }`
          : ""}
        {old_product !== new_product
          ? `product_name: ${new_details.product_name}`
          : ""}
      </b>
    </p>
  );
};

const access_toggled = (data, time, expanded) => (
  <p
    style={{
      justifySelf: "start",
      alignSelf: "center",
      marginBottom: "0.25rem",
    }}
  >
    <b>{data.specifics.access_details.product_name}</b> status changed to{" "}
    <b>{!data.specifics.access_details.status ? "enabled" : "disabled"}</b> by{" "}
    <b>{data.admin_email}</b>
    {expanded ? drawStatusExtra(data) : null}
    {expanded ? drawJobs(data) : ""}
  </p>
);

const user_deleted = (data, time, expanded) => (
  <p
    style={{
      justifySelf: "start",
      alignSelf: "center",
      marginBottom: "0.25rem",
    }}
  >
    <b>{data.specifics.user_details.email}</b> was deleted by{" "}
    <b>{data.admin_email}</b>
    {expanded ? drawStatusExtra(data) : null}
  </p>
);

const user_added = (data, time, expanded) => {
  const new_user_access = data.specifics.access_granted;
  let given_access_to =
    new_user_access && new_user_access.length > 0
      ? new_user_access.map((field, index) => {
          if (index < 1) return field;
          return `, ${field}`;
        })
      : "nothing";
  return (
    <p
      style={{
        justifySelf: "start",
        alignSelf: "center",
        marginBottom: "0.25rem",
      }}
    >
      <b>{data.specifics.user_details.email}</b> was created and given access to{" "}
      <b>{given_access_to}</b> by <b>{data.admin_email}</b>
      {expanded ? drawStatusExtra(data) : null}
    </p>
  );
};

const user_change = (data, time, expanded) => {
  if (!data.specifics) {
    return <React.Fragment />;
  }

  const old_details = data.specifics.before.user_details;
  const new_details = data.specifics.after.details;
  let detail_changes = Object.keys(new_details).map((field, index) => {
    if (index < 1) {
      return `${field.replace("_", " ")}: ${new_details[field]}`;
    }
    return `, ${field.replace("_", " ")}: ${new_details[field]}`;
  });

  const old_access = data.specifics
    ? data.specifics.before
      ? data.specifics.before.access
      : null
    : null;
  const new_access = data.specifics
    ? data.specifics.after
      ? data.specifics.after.access
      : null
    : null;

  const arraysEqual = (arr1, arr2) => {
    for (const item of arr1) {
      if (!arr2.includes(item)) {
        return false;
      }
    }
    for (const item of arr2) {
      if (!arr1.includes(item)) {
        return false;
      }
    }
    return true;
  };

  const are_arrays_equal =
    old_access && new_access ? arraysEqual(old_access, new_access) : null;

  return (
    <p
      style={{
        justifySelf: "start",
        alignSelf: "center",
        marginBottom: "0.25rem",
      }}
    >
      <b>{old_details.email}</b>
      {detail_changes.length > 0 ? "'s" : ""}{" "}
      {detail_changes.length === 0 && are_arrays_equal === true
        ? " was updated."
        : ""}{" "}
      {detail_changes.length > 0
        ? `details
      changed to `
        : ""}
      <b>
        {detail_changes ? (
          detail_changes.map((change) => change)
        ) : (
          <React.Fragment />
        )}
      </b>
      {detail_changes.length > 0 && are_arrays_equal === false ? " and " : ""}
      {are_arrays_equal === false ? "access changed to " : ""}
      {are_arrays_equal === false ? (
        <b>
          {new_access.length > 0
            ? new_access.map(
                (id, i) => `${id}${i + 1 !== new_access.length ? ", " : ""}`
              )
            : "Nothing"}
        </b>
      ) : (
        ""
      )}
      {expanded ? drawStatusExtra(data) : null}
    </p>
  );
};

export default DrawLogs;
