// NPM Dependencies
import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";

// Local Dependencies
import CustomInput from "../../../misc/CustomInput";
import Alert from "../../../misc/Alert";
import Breadcrumbs from "../../../misc/Breadcrumbs";
import PageHeader from "../../../misc/PageHeader";
import LoadingCircle from "../../../misc/Loading";
import { db } from "../../../../lib/firebase-compat";

// Context
import CloudFunctions from "../../../../Context";

class EditUserPage extends Component {
  static contextType = CloudFunctions;
  constructor(props) {
    super(props);
    this.focusRef = React.createRef();
    this.state = {
      redirect: null, // used for telling the routing system it's time to switch pages
      doc: null, // parent company doc
      products: undefined, // list of all products available
      companyAccesses: undefined, // access available to entire company
      userAccesses: undefined, // access currently assigned to this user
      defaultInputs: undefined, // values that were previously assigned to this user (used for prepopulating input fields)
      inputs: {}, // empty object until user starts editing the input fields
      alert: { msg: "", type: null }, // used to give feedback to user (this is sort of ugly, maybe just use logs?)
      defaultCheckboxes: {}, // exactly like defaultInputs, except with checkboxes
      checkboxes: {}, // exactly like inputs above, but with checkboxes
    };
  }

  initializeListeners = () => {
    const companiesCollection = db.collection("companies");
    const productsCollection = db.collection("products");
    const doc = companiesCollection.doc(this.props.companyId);

    this.companyUnsub = doc.onSnapshot((snapshot) => {
      this.setState({ doc: snapshot });
    });
    this.productsUnsub = productsCollection.onSnapshot((snapshot) => {
      this.setState({ products: snapshot.docs });
    });
    this.companyAccesses = doc.collection("access");
    this.users = db.collection("users");
  };

  // fetch document details from Firestore and initialize listeners
  async componentDidMount() {
    console.log(this.props);

    this.shopify = this.context;
    this.initializeListeners();

    // prepopulating user input fields
    this.currentUser = await this.users.doc(this.props.userId).get();
    const userData = this.currentUser.data();
    if (this.currentUser.exists) {
      this.setState({
        defaultInputs: {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
        },
      });
    } else {
      this.setAlert(
        "It looks like this document no longer exists...",
        "danger",
        5000
      );
    }

    // prepopulating user access checkboxes
    this.currentUserAccess = userData.access[this.props.companyId] || [];
    const checkboxes = this.currentUserAccess.filter((pid) => pid);
    this.setState({ defaultCheckboxes: checkboxes });

    this.companyAccessesUnsub = this.companyAccesses.onSnapshot((snapshot) => {
      this.setState({
        companyAccesses: snapshot.docs.filter(
          (product) => product.data().is_paused !== true
        ),
        userAccesses: snapshot.docs.filter((product) =>
          this.currentUserAccess.includes(product.data().product_id)
        ),
      });
    });
  }

  componentWillUnmount() {
    if (this.companyAccessesUnsub) this.companyAccessesUnsub();
    if (this.companyUnsub) this.companyUnsub();
    if (this.productsUnsub) this.productsUnsub();
    if (this.cancelAlert) this.cancelAlert();
  }

  getProduct = (pid) => {
    return this.state.products.filter((product) => {
      return product.id === pid;
    })[0];
  };

  getProductFromAccessID = (accessID) => {
    const accessDocument = this.state.companyAccesses.filter(
      (companyAccess) => companyAccess.id === accessID
    )[0];
    return this.getProduct(accessDocument.data().product_id);
  };

  handleInputChange = (e) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [e.target.id]: e.target.value,
      },
    });
  };

  handleCheckboxClick = (e) => {
    this.setState({
      checkboxes: {
        ...this.state.checkboxes,
        [e.target.id]: e.target.checked,
      },
    });
  };

  setAlert = (msg, alertType, time) => {
    this.cancelAlert();
    this.setState({
      alert: {
        msg: msg,
        type: alertType,
      },
    });
    this.timer = setTimeout(() => {
      this.setState({
        alert: {
          msg: "",
        },
      });
    }, time);
  };

  cancelAlert = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  createLocalLog = (logType, specifics) => {
    return this.state.doc.ref.collection("logs").add({
      log_type: logType,
      status: "pending",
      timestamp: new Date(),
      admin_id: this.props.user.uid,
      admin_email: this.props.user.email,
      specifics: {
        company_id: this.state.doc.id,
        user_id: this.props.userId,
        ...specifics,
      },
    });
  };

  submit = async () => {
    const oldUserData = this.currentUser.data();

    // update companies email cache if the current users email was updated
    if (this.state.inputs["email"]) {
      const cached_user_emails = this.state.doc.data().users_emails || [];
      this.state.doc.ref.update({
        users_emails: [
          ...cached_user_emails.filter((email) => email !== oldUserData.email),
          this.state.inputs["email"],
        ],
      });
    }

    // constructing new user access based off of state of checkboxes
    const adjustedAccessIDs = Object.keys(this.state.checkboxes);
    let newAccess = this.currentUserAccess;
    let wasAccessUpdated = false;
    if (adjustedAccessIDs.length !== 0) {
      adjustedAccessIDs.forEach(async (accessID) => {
        const productDocument = this.getProductFromAccessID(accessID);
        if (this.state.checkboxes[accessID] === true) {
          // first filter out the product id to prevent duplicates
          newAccess = newAccess.filter((id) => id !== productDocument.id);
          newAccess.push(productDocument.id);
          wasAccessUpdated = true;
        } else {
          newAccess = newAccess.filter((id) => id !== productDocument.id);
          wasAccessUpdated = true;
        }
      });
    }

    // user might still have access from other companies
    const combinedAccess = {
      ...oldUserData.access,
      [this.props.companyId]: newAccess,
    };

    // update user data in Firestore
    this.currentUser.ref.update({
      ...this.state.inputs,
      access: combinedAccess,
      access_history: {
        ...oldUserData.access_history,
        [this.props.companyId]: newAccess,
      },
    });

    // set up before and after objects for logs
    const before = {
      user_details: oldUserData,
      access: this.currentUserAccess.map(
        (pid) => this.getProduct(pid).data().product_name
      ),
    };
    const after = {};
    if (wasAccessUpdated) {
      after.access = newAccess.map(
        (pid) => this.getProduct(pid).data().product_name
      );
    }
    if (this.state.inputs) after.details = this.state.inputs;

    // redirect now for better UX while we make more HTTP requests below
    this.setState({
      redirect: `/company/${this.state.doc.id}`,
    });

    // create a new log
    const newLog = await this.createLocalLog("user_change", {
      before: before,
      after: after,
    });

    // make the final call to create an updateCustomer job
    try {
      const response = await this.shopify.updateCustomer({
        new_access: Object.keys(combinedAccess)
          .map((companyID) => combinedAccess[companyID])
          .reduce((acc, arr) => acc.concat(arr))
          .map((pid) => this.getProduct(pid).data().shopify_product_id),
        new_details: this.state.inputs,
        customer_id: oldUserData.associated_shopify_customer
          ? oldUserData.associated_shopify_customer.customer.id
          : -1,
        company_id: this.state.doc.id,
        companies: oldUserData.companies,
        user_id: this.currentUser.id,
        log_id: newLog.id,
      });
      if ("error" in response) {
        newLog.update({
          status: "failed",
          status_message: response.error,
        });
      } else {
        newLog.update({
          status: "in_queue",
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    const companyAccesses = this.state.companyAccesses;
    const defaultInputs = this.state.defaultInputs || {};
    let data = this.state.doc ? this.state.doc.data() : null;
    let id = this.state.doc ? this.state.doc.id : "";

    return (
      <div className="m0 listings-wrapper middle half-width">
        <Breadcrumbs
          style={{ marginTop: "1rem" }}
          pathways={[
            ["Companies", "/companies"],
            ["Company", `/company/${id}`],
            ["Edit User"],
          ]}
        />

        {/* page title */}
        <PageHeader
          small={"Edit User"}
          big={data ? data.company_name : ""}
          tiny={id}
        />

        {/* input fields */}
        {this.state.defaultInputs ? (
          <div>
            <div style={{ marginTop: "0.5rem" }}>
              <CustomInput
                name={"first_name"}
                label={`First Name ${
                  this.state.inputs.first_name
                    ? this.state.inputs.first_name !== defaultInputs.first_name
                      ? "(edited)"
                      : ""
                    : ""
                }`}
                onChange={this.handleInputChange}
                value={
                  this.state.inputs.first_name
                    ? this.state.inputs.first_name
                    : defaultInputs.first_name
                }
                type={"text"}
                title={"Edit users first name"}
              />
            </div>
            <div style={{ marginTop: "0.5rem" }}>
              <CustomInput
                name={"last_name"}
                label={`Last Name ${
                  this.state.inputs.last_name
                    ? this.state.inputs.last_name !== defaultInputs.last_name
                      ? "(edited)"
                      : ""
                    : ""
                }`}
                onChange={this.handleInputChange}
                value={
                  this.state.inputs.last_name
                    ? this.state.inputs.last_name
                    : defaultInputs.last_name
                }
                type={"text"}
                title={"Edit users last name"}
              />
            </div>
            <div style={{ marginTop: "0.5rem" }}>
              <CustomInput
                name={"email"}
                label={`Email ${
                  this.state.inputs.email
                    ? this.state.inputs.email !== defaultInputs.email
                      ? "(edited)"
                      : ""
                    : ""
                }`}
                onChange={this.handleInputChange}
                value={
                  this.state.inputs.email
                    ? this.state.inputs.email
                    : defaultInputs.email
                }
                type={"text"}
                title={"Edit users email"}
              />
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}

        {/* rendering access */}
        {this.state.companyAccesses !== undefined ? (
          <div style={{ marginTop: "1rem" }}>
            {companyAccesses.length > 0 ? (
              <React.Fragment>
                <p style={{ marginBottom: ".5rem" }}>
                  Products available to this user are based on the subscriptions
                  created for this company:
                </p>
                {companyAccesses.map((product) => {
                  const fetched = this.getProduct(product.data().product_id);
                  const p_name = fetched
                    ? fetched.data().product_name
                    : undefined;
                  if (p_name === undefined)
                    return <React.Fragment key={product.id} />;
                  const isExpired =
                    product
                      .data()
                      .expiration_date.toDate()
                      .setHours(23, 59, 0) < Date.now();
                  return (
                    <div key={product.id} style={{ display: "flex" }}>
                      <div
                        className="input-group mb-3"
                        title={`Toggle users access to ${p_name}`}
                      >
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <input
                              onChange={this.handleCheckboxClick}
                              id={product.id}
                              type="checkbox"
                              aria-label="Checkbox for following text input"
                              defaultChecked={this.currentUserAccess.includes(
                                product.data().product_id
                              )}
                              disabled={isExpired}
                            />
                          </div>
                        </div>
                        <label htmlFor={product.id} className="form-control">
                          {p_name}
                          {isExpired ? (
                            <span
                              style={{
                                color: "darkred",
                                marginLeft: "0.25rem",
                              }}
                            >
                              (expired)
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <div style={{ marginBottom: 0 }} className="alert alert-warning">
                There are no products available to give this this user access to
                because you haven't set up any company accesses yet. Click
                <Link to={`/company/${this.props.companyId}/new-access`}>
                  {" "}
                  here{" "}
                </Link>
                to create a new subscription for this company.
              </div>
            )}
          </div>
        ) : (
          <LoadingCircle />
        )}

        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}
        >
          <Alert
            style={{}}
            msg={this.state.alert.msg}
            alertType={this.state.alert.type}
          />
          <div style={{ marginLeft: "auto" }}>
            <button
              className="btn btn-primary btn-sm"
              onClick={this.submit}
              title={`Update ${defaultInputs.first_name}`}
            >
              Update User
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUserPage;
