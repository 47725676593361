// NPM Dependencies
import { collection, doc, DocumentData, limit, onSnapshot, orderBy, query, QueryDocumentSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { auth, db } from "../../../lib/firebase";

// Local Dependencies
import DrawCompanies from "../../companies/Companies";
import DrawLogs from "../../Logs";
import Breadcrumbs from "../../misc/Breadcrumbs";
import PageHeader from "../../misc/PageHeader";
import Pagination, { PageInfo, PageNumbers } from "../../misc/Pagination";
import Version from "./../../misc/Version";

function CompaniesPage() {
  const navigate = useNavigate();
  const params = useParams();

  const [searchInput, setSearchInput] = useState("");
  const [searchPage, setSearchPage] = useState(1);
  const [searchResults, setSearchResults] = useState<Array<QueryDocumentSnapshot<DocumentData>>>([]);
  const [numResults, setNumResults] = useState(5);
  const [currentPage, setCurrentPage] = useState(parseInt(params.pageNumber || ""));
  const [companies, setCompanies] = useState<Array<QueryDocumentSnapshot<DocumentData>>>([]);
  const [companiesLogs, setCompaniesLogs] = useState<Array<QueryDocumentSnapshot<DocumentData>>>();
  const [isLoadingMoreLogs, setIsLoadingMoreLogs] = useState(false);
  const [logLimit, setLogLimit] = useState(10);
  const [maxLogsReached, setMaxLogsReached] = useState(false);

  // init companies listener
  useEffect(() => {
    const companiesRef = collection(db, "companies");
    // TODO: use withConverter here for companies???
    const companiesQuery = query(companiesRef, orderBy("company_name", "asc"));
    const companiesUnsub = onSnapshot(
      companiesQuery,
      (snap) => {
        setCompanies(snap.docs.filter((doc) => doc.data().company_name))
      }, (err) => {
        // TODO: og code redirects to login page here in any error case
        // not needed I think, auth check happens at the root of the app,
        // so we shouldnt have issues here. leaving this TODO for now
        // in case this is an issue later
        console.log(err);
      }
    );

    return companiesUnsub;
  }, []);

  // init preferences listener
  useEffect(() => {
    if (auth.currentUser) {
      const prefsRef = doc(db, `admin_preferences/${auth.currentUser.email}`)
      const prefsUnsub = onSnapshot(
        prefsRef,
        (snap) => {
          const data = snap.data();
          if (data && data.results_per_page) {
            setNumResults(data.results_per_page);
          }
        }, (err) => {
          console.log(err);
        }
      );
      
      return prefsUnsub;
    }
  }, []);

  // init companies_logs listener
  useEffect(() => {
    const logsRef = collection(db, "companies_logs");
    const logsQuery = query(logsRef, orderBy("timestamp", "desc"), limit(logLimit));
    const logsUnsub = onSnapshot(
      logsQuery,
      (snap) => {
        setCompaniesLogs(snap.docs);
        setIsLoadingMoreLogs(false);
        if (snap.size < logLimit) {
          setMaxLogsReached(true);
        }
      }, (err) => {
        console.log(err);
      }
    );

    return logsUnsub;
  }, [logLimit]);

  const editCompany = (id: string) => {
    navigate(`/company/${id}`);
  };

  const search = (e: React.ChangeEvent<HTMLInputElement>) => {
    let search = e.target.value;
    const searchResults = companies.filter((doc) => {
      let currentName = doc.data().company_name;
      if (!currentName) {
        console.log("company_name property missing from: ", doc.id);
        return false;
      }
      // we already have quite a few document reads so --->
      const users = doc.data().users_emails;
      if (users) {
        let found = false;
        users.forEach((user: string) => {
          if (
            user.toLowerCase().includes(search.toLowerCase()) ||
            user === search
          ) {
            found = true;
          }
        });
        if (found) return true;
      }
      return (
        doc.id === search ||
        currentName.toLowerCase().includes(search.toLowerCase()) ||
        currentName === search
      );
    });

    setSearchInput(e.target.value);
    setSearchResults(searchResults);
  };

  const changePage = (pageNumber: number) => {
    if (searchInput === "") {
      navigate(`/companies/${pageNumber}`);
      setCurrentPage(pageNumber);
    } else {
      setSearchPage(pageNumber);
    }
  };

  const showMoreLogs = () => {
    if (!companiesLogs) {
      throw new Error("cant show more logs with companiesLogs being undefined");
    }
    const newLimit = companiesLogs.length + 10;
    setIsLoadingMoreLogs(true);
    setLogLimit(newLimit);
  };

  const restoreCompany = (companyId: string, companyName: string, logId: string) => {
    // TODO: finish implementing. I dont like this function, it restores the company,
    // but it's not a real "restore" as it doesn't restore any of the users. In any
    // case, it's not functionality that we use regularly, so we can rethink this
    // 1. create new global log "company_restore"
    // 2. update company-level deleted log with was_restored: true
    // 3. recreate the company with the same companyId and companyName
    throw new Error("temporarily disabled until we decide how to properly implement this functionality");
  };

  // TODO: there might be a good way to refactor or get rid of the below
  // all together

  const rangeMin = (currentPage - 1) * numResults;
  const rangeMax = (currentPage - 1) * numResults + numResults;

  let comps: QueryDocumentSnapshot<DocumentData>[] | undefined = undefined;
  let compsUnsliced: QueryDocumentSnapshot<DocumentData>[] | undefined  = [];
  if (companies !== undefined) {
    if (searchInput === "") {
      comps = companies.slice(rangeMin, rangeMax);
      compsUnsliced = companies;
    } else {
      comps = searchResults.slice(rangeMin, rangeMax);
      compsUnsliced = searchResults;
    }
  }

  return (
    <div className="split-view">
      <div className="m0 listings-wrapper left">
        <Breadcrumbs
          style={{ marginTop: "1rem" }}
          pathways={[["Companies", "/companies"]]}
        />
        <PageHeader big={"Companies"} />
        <div style={{ textAlign: "right" }}>
          <Link title={"Add a new company"} to="/new-company">
            + New Company & Access
          </Link>
        </div>
        <input
          className="form-control"
          style={{ marginTop: "2rem" }}
          placeholder="Search..."
          onChange={search}
        />
        <DrawCompanies companies={comps} editCompany={editCompany} />
        <Pagination
          iterable={compsUnsliced}
          currentPage={
            searchInput === "" ? currentPage : searchPage
          }
          numResults={numResults}
          changePage={changePage}
          style={{ display: "flex", margin: "1rem 0", alignItems: "center" }}
        >
          <PageInfo keyword={"companies"} />
          <PageNumbers style={{ marginLeft: "auto" }} />
        </Pagination>
      </div>
      <DrawLogs
        className="m0 logs-listings-wrapper right"
        showMore={showMoreLogs}
        showMoreDisabled={maxLogsReached}
        isLoadingMoreLogs={isLoadingMoreLogs}
        logs={companiesLogs}
        restoreCompany={restoreCompany}
      />
      <Version />
    </div>
  );
}

export default CompaniesPage;
