/** Temporary compatibility module for parts of the code base still using
 * Firebase v8 & below.
 */

import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import env from "../env.json";

const app = firebase.initializeApp(env.firebaseConfig);
const auth = firebase.auth(app);
const db = firebase.firestore(app);
const functions = firebase.functions(app);

// NOTE: doing the below check means that the whole app needs
// the emulators running to work properly
if (window.location.hostname.includes("192.168.1.11")) {
  console.log("initializing compatibility emulators...");
  auth.useEmulator("http://192.168.1.11:9099");
  db.useEmulator("192.168.1.11", 8080);
  functions.useEmulator("192.168.1.11", 5001);
}

export { app, auth, db, functions };
