import React from "react";

// The reason for moving context in another file is that I have a cyclic dependency
// between App.js and whatever child component is importing the context from App.js
const CloudFunctions = React.createContext();

// TODO: plan is to slowly migrate everything to use the new context instead
// of this one
export const CloudFunctionsProvider = (props) => {
  return (
    <CloudFunctions.Provider value={props.shopify}>
      {props.children}
    </CloudFunctions.Provider>
  );
};

export default CloudFunctions;
