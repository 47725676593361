// NPM Dependencies
import React from "react";

// Local Dependencies
import LoadingCircle from "./../misc/Loading";

function DrawCompanies(props) {
  if (props.companies === undefined) {
    return <LoadingCircle />;
  }
  const seperate_name = (doc) => doc.data().company_name.split(" ");
  return props.companies
    .filter((doc) => seperate_name(doc).filter((str) => str !== "").length > 0)
    .map((doc) => {
      return (
        <div className="listing" key={doc.id}>
          <h4
            className="font-weight-bold"
            style={{
              justifySelf: "start",
              alignSelf: "center",
              margin: 0,
              fontSize: "18px",
            }}
          >
            {doc.data().company_name}
          </h4>
          <span style={{ marginLeft: "auto" }}>
            <button
              className="btn btn-light"
              style={{ marginRight: ".375rem" }}
              onClick={props.editCompany.bind(this, doc.id)}
              title={`Edit company "${doc.data().company_name}"`}
            >
              <i className="fas fa-pen"></i>
            </button>
          </span>
        </div>
      );
    });
}

export default DrawCompanies;
