// NPM Dependencies
import React, { Component } from "react";

// Local Dependencies
import DeleteButton from "../misc/Delete";
import LoadingCircle from "../misc/Loading";
import env from "./../../env.json";

class UserEmail extends Component {
  state = {
    is_hovered: false,
  };

  render() {
    return (
      <span>
        <a
          className="font-weight-bold"
          style={{
            justifySelf: "start",
            alignSelf: "center",
            margin: 0,
            fontSize: "18px",
            color: "black",
          }}
          onMouseOver={() => this.setState({ is_hovered: true })}
          onMouseLeave={() => this.setState({ is_hovered: false })}
          href={this.props.link || "#"}
          title={this.props.link || ""}
          target={this.props.link ? "_blank" : ""}
        >
          {this.props.children}
        </a>{" "}
        {this.state.is_hovered && this.props.link ? (
          <i className="fab fa-shopify"></i>
        ) : null}
      </span>
    );
  }
}

function DrawAdditionalUsers(props) {
  if (props.users === undefined) {
    return <LoadingCircle />;
  }
  if (props.users && props.users.length > 0) {
    return props.users.map((doc) => {
      const data = doc.data();
      const shopify_id = data
        ? data.associated_shopify_customer
          ? data.associated_shopify_customer.customer
            ? data.associated_shopify_customer.customer.id
            : null
          : null
        : null;
      return (
        <div className="listing" key={doc.id}>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "0px",
              whiteSpace: "nowrap",
            }}
          >
            <UserEmail
              link={
                shopify_id
                  ? `https://${
                      env.shopify_store_name || "bfg-dev"
                    }.myshopify.com/admin/customers/${shopify_id}`
                  : null
              }
            >
              {data.email || "NULL EMAIL VALUE"}
            </UserEmail>
            <i
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "rgb(85, 85, 85)",
                margin: 0,
                fontSize: "13px",
                fontWeight: "normal",
                whiteSpace: "nowrap",
              }}
            >
              FUID: {doc.id}
            </i>
          </span>

          <span
            style={{
              marginLeft: "auto",
              display: "flex",
              flexDirection: "row",
              marginTop: "auto",
              marginBottom: "auto",
              flex: "none",
            }}
          >
            <button
              className="btn btn-light"
              style={{ marginRight: ".375rem" }}
              onClick={props.editUser.bind(this, doc.id)}
              title={`Edit user "${data.email}"`}
            >
              <i className="fas fa-pen"></i>
            </button>
            <DeleteButton
              object_name={"user"}
              delete={props.delete.bind(this, doc.id)}
              title={`Delete user "${data.email}"`}
            />
          </span>
        </div>
      );
    });
  }
  return (
    <div
      className={`alert alert-warning`}
      role="alert"
      style={{ width: "100%" }}
    >
      There are no users to display.
    </div>
  );
}

export default DrawAdditionalUsers;
