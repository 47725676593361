/** Everything related to UMA companies. */
import { DocumentData, QueryDocumentSnapshot, where, query, collection, doc, getDocs, getDoc, addDoc, setDoc, updateDoc, deleteDoc, onSnapshot, FirestoreError } from "firebase/firestore";
import { IDims } from "../../components/pages/company/access/RLSDims";
import { db } from "../firebase";
import Access, { accessConverter } from "./access";
import Client, { clientCoverter } from "./clients";

// TODO: make this class so it more closely aligns with the other classes/data types.
// Company classes are really only going to be created by converters, so no need to try
// and make it multi-purpose and able to create classes. We'll instead create a
// createCompany function or something similar
class Company {
  companyName?: string;
  docSnap?: QueryDocumentSnapshot;
  clients?: Client[];
  docId?: string;

  constructor(initOptions: {
    companyName?: string;
    docId?: string;
    docSnap?: QueryDocumentSnapshot;
  }) {
    this.companyName = initOptions.companyName;
    this.docId = initOptions.docId;
    this.docSnap = initOptions.docSnap;
    // this.getCompanyFromFirestore();
  };

  static fromFirestore = (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data();
    return new Company({
      companyName: data.product_name,
      docId: snapshot.id,
      docSnap: snapshot
    });
  };

  // TODO: run this whole file through a formatter especially below function
  // TODO: this function should just be turned into something called resync()
  // or something and change it's use to instead be to resync the whole company
  // and all its data instead of just fetching the document snapshot
  /** Get the companies document from Firestore and caches it. */
  getCompanyFromFirestore = async () => {
    // check if docSnap already exists, if it does, just return that
    if (this.docSnap) {
      return this.docSnap;
    }

    if (this.docId) {
      const docRef = doc(db, `companies/${this.docId}`);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error(`firestore doc (id: ${this.docId}) doesn't exist`);
      }
      this.docId = docSnap.id;
      this.docSnap = docSnap;
      this.companyName = docSnap.data().company_name;
    } else if (this.companyName) {
      const companiesRef = collection(db, "companies");
      const companyQuery = query(
        companiesRef,
        where("company_name", "==", this.companyName)
      );
      const companyDocs = await getDocs(companyQuery);
      if (companyDocs.docs.length === 0) {
        // no company with this name found
        throw new Error(`no company found with companyName: ${this.companyName}`);
      } else if (companyDocs.docs.length === 1) {
        this.docId = companyDocs.docs[0].id;
        this.docSnap = companyDocs.docs[0];
      } else if (companyDocs.docs.length > 1) {
        console.warn(`UMA was designed to work with unique company names but 
          found 2 companies with the same name (${this.companyName}). This might 
          mean there is a bug in the application somewhere and might cause 
          unpredictable behavior when updating access for this companies 
          clients. Using company with id: ${companyDocs.docs[0].id}`);
        this.docId = companyDocs.docs[0].id;
        this.docSnap = companyDocs.docs[0];
      }

    } else {
      throw new Error("cant find company with no companyName or docId set");
    }

    return this.docSnap?.ref;
  }

  /** Create the company if it doesn't exist */
  create = async (companyName: string) => {
    if (this.docId || this.docSnap) {
      throw new Error("company already exists");
    }

    const allCompaniesRef = doc(db, "companies/_all_companies");
    let allCompaniesData = (await getDoc(allCompaniesRef)).data();

    if (allCompaniesData === undefined) {
      // allCompaniesDoc doesn't exist, create it
      console.log("creating _all_companies meta document");
      await setDoc(allCompaniesRef, { companies: [] });
      allCompaniesData = (await getDoc(allCompaniesRef)).data();
    }

    if (allCompaniesData && allCompaniesData.companies.includes(companyName)) {
      throw new Error("company already exists");
    }

    const companiesRef = collection(db, "companies");
    const newCompanyDoc = await addDoc(companiesRef, {
      company_name: companyName
    });
    await updateDoc(
      allCompaniesRef, {
        companies: ((allCompaniesData?.companies as Array<string>) || []).push(companyName)
      }
    );

    return newCompanyDoc;
  };

  /** Update the company name. */
  updateCompanyName = async (newCompanyName: string) => {
    await this.getCompanyFromFirestore();
    if (!this.docSnap) {
      throw new Error(
        "failed to fetch company document when trying to update the company name"
      );
    }
    await updateDoc(this.docSnap.ref, {
      company_name: newCompanyName
    });
  };

  // TODO: finish implementing this
  delete = async () => {
    // 1. check if doc exists
    // 2. fetch companies/_all_companies meta doc and filter out this company's name
    // 3. validate meta doc was update (no errors)
    // 4. create local log indicating company was deleted & wait til it finishes
    // 5. create global log indicating company was deleted (company_deleted)
    // 6. iterate through all the users and delete them
    // 7. delete the company firestore doc
    // 8. return
    if (!this.docSnap?.exists()) {
      throw new Error("cant delete company if a Firestore doc does not exist for it");
    }

    const allCompaniesDocRef = doc(db, "companies/_all_companies");
    const allCompaniesDocSnap = await getDoc(allCompaniesDocRef);
    if (allCompaniesDocSnap.exists()) {
      // filter out company name
    }

    // const logDocRef = await this.createNewLog("company_deleted");
    // logDocRef.update({
    //   status: "resolved"
    // });
    // this.createGlobalLog("company_deleted");

    this.clients?.forEach((client) => client.delete());
    this.delete();

    // if (this.state.doc) {
    //   const all_companies = await this.props.db
    //     .doc("companies/_all_companies")
    //     .get();
    //   if (all_companies.exists) {
    //     all_companies.ref.update({
    //       companies: ((all_companies.data() || {}).companies || []).filter(
    //         (company_name) =>
    //           company_name !== this.state.doc.data().company_name
    //       ),
    //     });
    //   }
    //   const log = this.createLocalLog("company_deleted");
    //   await log.promise;
    //   log.doc.update({
    //     status: "resolved",
    //   });
    //   this.createGlobalLog("company_deleted");
    //   // iterate through existing users and remove them from Shopify
    //   this.state.users.forEach((user) => {
    //     this.deleteUser(user.ref.id);
    //   });
    //   this.state.doc.ref.delete();
    //   this.setState({
    //     redirect: "/companies",
    //   });
    // }
  };

  // TODO: for getAcces,  instead of returning documents for a company's access,
  // return an "Access" class abstraction instead.
  /** Fetches the company's access from Firestore. */
  getAccess = async (): Promise<Access[]> => {
    await this.getCompanyFromFirestore();
    const accessRef = collection(db, `companies/${this.docId}/access`)
      .withConverter(accessConverter);
    const accessSnap = await getDocs(accessRef);
    return accessSnap.docs.map((docSnap) => docSnap.data());
  };

  /** Creates a new access listener. */
  getAccessListener = (
    onNext: (snapshot: Access[]) => void,
    onError?: (error: FirestoreError) => void
  ) => {
    // await this.getCompanyFromFirestore();
    const accessRef = collection(db, `companies/${this.docId}/access`)
      .withConverter(accessConverter);
    const unsubscribe = onSnapshot(accessRef, (snap) => {
      const accesses = snap.docs.map((accessSnap) => accessSnap.data());
      onNext(accesses);
    }, (err) => {
      onError && onError(err);
    });
    return unsubscribe;
  };

  newAccess = async (productId: string, expirationDate: Date, rls?: IDims[]): Promise<Access> => {
    await this.getCompanyFromFirestore();
    // TODO: convert the expirationDate number into a UTC date object (or 
    // whichever timezone the UMA functions are running in) and set the
    // hours to 23:55 (almost midnight).
    // Would also be good to test if we can just convert the number timestamp
    // into a local date object. I think if we do that, when we convert back
    // to a number there will be a difference from the timestamp UMA runs in
    // Once we've converted back to a numer timestamp, we can convert that into
    // a Firestore.Timestamp object
    const accessesRef = collection(db, `companies/${this.docId}/access`)
      .withConverter(accessConverter);
    const newAccess = new Access({
      productId: productId,
      expirationDate: expirationDate,
      isPaused: false,
      rls: rls
    });
    const accessRef = await addDoc(accessesRef, newAccess);
    newAccess.docId = accessRef.id;
    newAccess.docRef = accessRef.withConverter(null);
    return newAccess;
  };

  pauseAccess = async (accessId: string): Promise<void> => {
    await this.getCompanyFromFirestore();
    const accessRef = doc(db, `companies/${this.docId}/access/${accessId}`);
    return await updateDoc(accessRef, { is_paused: true });
  };

  resumeAccess = async (accessId: string): Promise<void> => {
    await this.getCompanyFromFirestore();
    const accessRef = doc(db, `companies/${this.docId}/access/${accessId}`);
    return await updateDoc(accessRef, { is_paused: false });
  };

  /** Removes the company's access from Firestore. */
  deleteAccess = async (accessId: string): Promise<void> => {
    await this.getCompanyFromFirestore();
    const accessRef = doc(db, `companies/${this.docId}/access/${accessId}`);
    return await deleteDoc(accessRef);
  };

  /** Checks client access against company access and validates that the client
   * doesn't have access to anything they shouldn't
   */
  auditClientAccess = async (): Promise<void> => {};

  getClientEmails = async (): Promise<Array<string>> => {
    await this.getCompanyFromFirestore();
    if (!this.docSnap) {
      throw new Error("cant get client emails with an invalid docSnap");
    }
    return this.docSnap.data().users_emails || [];
  }

  /** Fetches the clients from this company from Firestore. */
  getClients = async (): Promise<Client[]> => {
    await this.getCompanyFromFirestore();

    if (this.docId === undefined) {
      throw new Error("cant fetch clients with no company docId");
    }

    const usersRef = collection(db, "users");
    const usersQuery = query(
      usersRef,
      where("companies", "array-contains", this.docId)
    ).withConverter(clientCoverter);
    const clientsSnap = await getDocs(usersQuery);
    this.clients = clientsSnap.docs.map((clientSnap) => clientSnap.data());

    return this.clients;
  };

  /** Creates a new access listener. */
  getClientListener = (
    onNext: (snapshot: Client[]) => void,
    onError?: (error: FirestoreError) => void
  ) => {
    // await this.getCompanyFromFirestore();
    const usersRef = collection(db, "users");
    const usersQuery = query(
      usersRef,
      where("companies", "array-contains", this.docId)
    ).withConverter(clientCoverter);
    const unsubscribe = onSnapshot(usersQuery, (snap) => {
      const clients = snap.docs.map((clientSnap) => clientSnap.data());
      onNext(clients);
    }, (err) => {
      onError && onError(err);
    });
    return unsubscribe;
  };

  // TODO: finish implementing
  createClient = () => {
    // NewSingleUser page component does this, can copy from there
    // 1. create the user in Firestore
    // 2. call server side createCustomer API
  };

  // TODO: finish implementing
  deleteClient = async (client: Client | string): Promise<void> => {
    if (typeof client === "string") {
      // client id was passed instead of client object,
      // create a ref to the client and attempt to delete
      return;
    }
    await client.delete();
  }

  // TODO: finish implementing
  createNewLog = () => {};
}

/** Use to convert Firestore data to `Company` objects and vise versa. */
const companyConverter = {
  toFirestore(company: Company): DocumentData {
    return {};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot
  ): Company {
    return Company.fromFirestore(snapshot);
  },
};

export default Company;
export { companyConverter };
