import React from "react";

function Alert(props) {
  return (
    <div>
      {props.msg ? (
        <div
          className={`alert alert-${
            props.alertType ? props.alertType : "primary"
          }`}
          role="alert"
          style={props.style ? props.style : { margin: ".5rem" }}
        >
          {props.msg}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Alert;
