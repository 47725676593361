import React from "react";
import { Link } from "react-router-dom";

function ErrorPage(props) {
  return (
    <div style={{ marginTop: "3rem", textAlign: "center" }}>
      <h1 className="font-weight-bold">{props.errorCode}</h1>
      <h5 style={{ marginTop: "1.25rem" }}>
        Uh oh! This page doesn't seem to exist{" "}
        <span role="img" aria-label="loudly crying face">
          😭
        </span>
        <br />
        <br />
        Click <Link to="/companies/1">here</Link> to go back.
      </h5>
    </div>
  );
}

export default ErrorPage;
