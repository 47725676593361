import React from "react";

import env from "./../../env.json";

const currentEnvironment = env.environment;
const currentVersion = env.version;

const defaultStyle = {
  color: "#6c757d",
  textAlign: "right",
};

function Version(props) {
  return (
    <div
      className={props.className || "left"}
      style={props.style || defaultStyle}
    >
      <span>{`${currentEnvironment}`}</span>
      {currentEnvironment !== "" && currentVersion !== "" ? (
        <React.Fragment>{" | "}</React.Fragment>
      ) : (
        <React.Fragment />
      )}
      <span>{`${currentVersion}`}</span>
    </div>
  );
}

export default Version;
